<template>
  <div class="AddTreatment">
    <b-card>
      <b-row>
        <b-col>
          <div style="font-weight: bold">{{ $t("ChooseMainSpecialty") }}</div>

          <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            class="w-full"
            label="SpecialityName"
            v-model="mainSpeciality"
            :options="surgeryCategories"
            :reduce="(item) => item"
            :placeholder="$t('ParentSpecialities')"
            @input="selectSurgCategory(mainSpeciality)"
          >
          </v-select>
        </b-col>
        <b-col>
          <div style="font-weight: bold">{{ $t("ChooseSubSpecialty") }}</div>

          <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            class="w-full"
            label="SpecialityName"
            v-model="subSpeciality"
            :options="surgeriesByCat"
            :reduce="(item) => item"
            :placeholder="$t('ChiledSpecialities')"
            @input="selectSurgCategory(subSpeciality)"
          >
          </v-select>
        </b-col>
      </b-row>
    </b-card>


    <h2 class="mt-2">{{ $t("AddTreatments") }}</h2>
    <b-row >
     <b-col cols="12" md="6">
      <b-card
        id="custumcard"

        style="height: 40vh; overflow-y: scroll"
      >
        <h3 class="ActiveCardHeader primaryBackColor">
          {{ $t("Treatments") }}
        </h3>
        <div class="m-1">
          <div class="primarycolor mt-1 vx-row">
            <feather-icon
              style="width: 15px"
              icon="PlusCircleIcon"
              class="cursor-pointer m-1"
            >
            </feather-icon>
            <u @click="AddNewTreatement()" style="text-decoration: underline" class="mt-1">{{
              $t("CreateNewTreatment")
            }}</u>
          </div>

          <vs-input
            v-model="search.treatmentName"
            :placeholder="$t('Search')"
            class="w-full"
            name="search"
          />
          <div
            v-for="item in filterSurgery"
            :key="item.ID"
            :class="item.IsSelected ? 'Active' : 'InActive'"
            class="shadow-md w-full mt-2"
          >

            <b-row>

              <vs-checkbox
                v-model="item.IsSelected"
                @input="SurgeryChecked(item)"
              ></vs-checkbox>
              {{ item.Name }}
            </b-row>
          </div>
        </div>
      </b-card>
     </b-col>

      <b-col cols="12" md="6">
        <b-card
        id="custumcard"
        style="height: 40vh; overflow-y: scroll"
      >
      <h3 class="ActiveCardHeader secondaryBackColor">
          {{ $t("AllAddedTreatments") }}
        </h3>
        <!-- <h3 class="primarycolor">{{ $t("AllAddedTreatments") }}</h3> -->
        <div class="m-1">
          <point
            v-for="(item, index) in hospitalSurgery"
            @editTreatment="editTreatment(item)"
            :data="item.Surgery"
            :key="index"
            :hameEdit="true"
          />
        </div>
      </b-card>

      </b-col>

      </b-row>

    <div class="w-full m-10">
      <div class="text-right" style="color: #004477">
        <u @click="SaveTreatments()" style="font-size: 20px;text-decoration: underline;">{{
          $t("Save")
        }}</u>
      </div>
    </div>
    <b-button
    type="border" color="danger"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="$router.go(-1)"
    >
      {{$t('back')}}
    </b-button> 
  </div>
</template>

<script>
import HospitalModule from "@/store/hospital/moduleHospital.js";
import SurgeryModule from "@/store/settings/surgery/moduleSurgery.js";
import point from "@/components/point.vue";
import {
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardHeader,
  BCardText,
  BCol,
  BImg,
  BLink,
  BRow,
  BFormCheckbox,
} from "bootstrap-vue";
export default {
  components: { point,BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardHeader,
  BCardText,
  BCol,
  BImg,
  BLink,
  BRow,
  BFormCheckbox, },
  data() {
    return {
      selectedSpecialityFilter: {},
      search: { treatmentName: "" },
      hospitalSurgery: [],
      isAll: false,
      ActiveIndex: null,
      SecondActiveIndex: null,
      MainsurgeriesByCat: [],
      surgeriesByCat: [],
      surgeryCategories: [],
    };
  },
  methods: {
    editTreatment(surgery) {
      if (this.hospitalSurgery.findIndex((obj) => !obj.ID) == -1)
        this.$router.push("/hospital/Add-EditTreatmentSurgery/" + surgery.ID);
      else
        this.$vs.notify({
          title: this.$t("Info"),
          text: this.$t("editTreatmentSurgery"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });
    },
    back() {
      this.$router.go(-1);
    },

    MakeActive(index) {
      this.SecondActiveIndex = index;
    },
    selectSurgCategory(data, index) {
      this.ActiveIndex = index;
      this.SecondActiveIndex = null;
      if (data != null) {
        this.surgeriesByCat = this.MainsurgeriesByCat.filter(
          (ob) => ob.Specialty.ParentSpecialtyID == data.Specialty.ID
        );
        this.isAll = false;
      } else {
        this.surgeriesByCat = this.MainsurgeriesByCat.filter(
          (ob) => ob.Specialty.ParentSpecialtyID
        );
      }
    },
    SurgeryChecked(surgery) {
      if (surgery.IsSelected) {
        this.hospitalSurgery.push({ SurgeryID: surgery.ID, Surgery: surgery });
      } else {
        var index = this.hospitalSurgery.findIndex(
          (obj) => obj.Surgery.ID == surgery.ID
        );
        this.hospitalSurgery.splice(index, 1);
      }
    },
    SaveTreatments() {
      var vm = this;
      this.$vs.loading();
      this.hospitalSurgery.forEach(
        (obj) => (obj.HospitalID = vm.$store.state.AppActiveUser.Hospital.ID)
      );
      this.$store
        .dispatch("HospitalList/SaveHospitalSurgeries", this.hospitalSurgery)
        .then((res) => {
          this.$vs.loading.close();
          this.getHospitalSurgeries();
          this.$vs.notify({
            title: this.$t("success"),
            text: res.data.Message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: this.$t("Error"),
            text: err.data.Message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    AddNewTreatement() {
      this.$router.push("/hospital/Add-EditTreatmentSurgery");
    },
    searchHospitalSpecialty() {
      debugger;
      this.$store
        .dispatch("HospitalList/SearchHospitalSpecialtys", {
          HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
        })
        .then((res) => {
          this.surgeryCategories = res.data.Data.filter(
            (x) => x.Specialty.ParentSpecialtyID == null
          );
          this.MainsurgeriesByCat = res.data.Data.filter(
            (x) => x.Specialty.ParentSpecialtyID != null
          );
          this.surgeriesByCat = this.MainsurgeriesByCat;
        });
    },
    setcheckedItems() {
      if (this.hospitalSurgery && this.allSurgeries)
        this.allSurgeries.forEach((element) => {
          if (
            this.hospitalSurgery.findIndex(
              (obj) => obj.SurgeryID == element.ID
            ) != -1
          )
            element.IsSelected = true;
        });
    },
    getHospitalSurgeries() {
      this.$store
        .dispatch("HospitalList/SearchHospitalSurgies", {
          HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
        })
        .then((res) => {
          this.hospitalSurgery = res.data.Data;
          this.setcheckedItems();
        });
    },
  },
  computed: {
    allSurgeries() {
      return this.$store.state.SurgeryList.surgeries;
    },
    filterSurgery() {
      debugger;
       debugger;
      var filtersurgeries = [];

      if (this.isAll == true) filtersurgeries = this.allSurgeries;
      filtersurgeries = this.allSurgeries;
      if (this.search.treatmentName && this.search.treatmentName != "") {
        debugger;
        filtersurgeries = filtersurgeries.filter((obj) =>
          obj.Name != null && obj.Name.includes(this.search.treatmentName)
        );
        debugger;
      }
      debugger;

      if (this.selectedSpecialityFilter.ID) {
        filtersurgeries = filtersurgeries.filter(
          (obj) => obj.SpecialtyID == this.selectedSpecialityFilter.ID
        );
      }

      debugger
      return filtersurgeries;
    },
    specialities() {
      return this.$store.state.HospitalList.hospitalSpecialties;
    },
  },
  created() {
    if (!HospitalModule.isRegistered) {
      this.$store.registerModule("HospitalList", HospitalModule);
      HospitalModule.isRegistered = true;
    }
    if (!SurgeryModule.isRegistered) {
      this.$store.registerModule("SurgeryList", SurgeryModule);
      SurgeryModule.isRegistered = true;
    }

    this.getHospitalSurgeries();
    this.$store.dispatch("SurgeryList/GetAllSurgeries").then((res) => {
      this.setcheckedItems();
    });

    this.searchHospitalSpecialty();
    debugger;
  },
};
</script>
<style >
.ActiveCardHeader {
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}
#custumcard .vs-card--content {
  padding: unset;
}
#customRow .vx-col {
  padding: unset;
}
</style>